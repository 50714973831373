import React from 'react'
import Container from 'react-bootstrap/container'
import filter from 'lodash/filter'
// custom
import Layout from 'src/components/view/layout'
import SEO from 'src/components/view/seo'
import UserForm from 'src/components/pages/account/user-form'
import { userConfig } from 'src/components/pages/account/config'

function Login() {
  return (
    <Layout>
      <SEO title="Login" />
      <Container>
        <h1 className="pt-5 text-center text-capitalize text-white">Login</h1>
        <UserForm
          type="login"
          items={filter(userConfig, v => v.name !== 'displayName')}
        />
      </Container>
    </Layout>
  )
}

export default Login
